<template>
  <div class="main">
    <!--电脑端页面-->
    <template v-if="!isPhoneWidth">
      <!--左边选择框-->
      <div class="left-bar">
        <!--地图选项-->
        <el-card class="box-card" :body-style="{ padding: '5px' }">

          <div class="map-agent">
            <!--选择地图-->
            <div class="select-map" @click="showMapOptions">
              <img :src="config.maps[this.selectMap].imgSrc" alt="">

              <div class="select-map-title">
                <div>
                  {{ config.maps[this.selectMap].title }}
                </div>
                <div>
                  {{ config.maps[this.selectMap].chTitle }}
                </div>
              </div>
            </div>
            <!--选择特工-->
            <div class="select-agent" @click="showAgentOptions" :style="config.agents[this.selectAgent].background">
              <img :src="config.agents[this.selectAgent].imgSrc" alt="">
              <div class="agent-title">{{ config.agents[this.selectAgent].chTitle }}</div>
            </div>
          </div>
          <!--技能-->
          <el-divider content-position="left">技能</el-divider>
          <div class="ability-options">
            <template v-for="(value, name, index) in config.agents[selectAgent].ability">
              <template
                  v-if="mapToAgent[selectMap] && mapToAgent[selectMap][selectAgent] && mapToAgent[selectMap][selectAgent][name]">
                <el-badge :value="mapToAgent[selectMap][selectAgent][name]" class="item" :key="index">
                  <div class="ability-item" @click="clickAbilityOption(name)">
                    <img :src="value.imgSrc" alt="" v-bind:class="{'img-opacity2': selectAbility!=name}">
                  </div>
                </el-badge>
              </template>
              <template v-else>
                <div class="ability-item" @click="clickAbilityOption(name)" :key="index">
                  <img :src="value.imgSrc" alt="" v-bind:class="{'img-opacity2': selectAbility!=name}">
                </div>
              </template>
            </template>
          </div>
          <!--其他-->
          <template v-if="mapAgentVideoList.length!==0">
            <el-divider content-position="left">其他</el-divider>
            <div v-for="(item, index) in mapAgentVideoList" :key="index" class="text item">
              <a target="_blank" :href="item.link"><i class="iconfont icon-shipin" style="font-size: 14px"></i>{{
                  item.title
                }}</a>
              <el-popconfirm
                  v-if="$store.state.userinfo.isAdmin"
                  title="确定删除吗？"
                  @confirm="delRelatedVideo(item.id)"
              >
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-card>

        <!--添加lineup-->
        <el-card class="box-card" style="margin-top: 20px" v-if="$store.state.userinfo.isAdmin">
          <div slot="header" class="clearfix">
            <span style="font-weight: 600">添加LINEUP</span>
          </div>
          <el-row>
            <el-col :span="4">ID</el-col>
            <el-col :span="18">
              <el-input size="mini" v-model="lineupForm.blogId"></el-input>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="4">标题</el-col>
            <el-col :span="18">
              <el-input size="mini" v-model="lineupForm.title"></el-input>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="4">站点</el-col>
            <el-col :span="10">
              <el-input :disabled="true" size="mini" v-model="lineupForm.startPoint"></el-input>
            </el-col>
            <el-col :span="10">
              <el-button type="primary" size="mini" @click="selectPoint('start')">地图选点</el-button>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="4">落点</el-col>
            <el-col :span="10">
              <el-input :disabled="true" size="mini" v-model="lineupForm.endPoint"></el-input>
            </el-col>
            <el-col :span="10">
              <el-button type="primary" size="mini" @click="selectPoint('end')">地图选点</el-button>
            </el-col>
          </el-row>
          <!--创建一个范围-->
          <el-row style="margin-top: 10px">
            <el-col :span="4">范围</el-col>
            <el-col :span="10">
              <template v-if="lineupForm.isTempPolygon">
                <el-button type="success" size="mini" @click="selectRangeDone">创建完成</el-button>
              </template>
              <template v-else>
                <el-button type="primary" size="mini" @click="selectRange">创建范围</el-button>
              </template>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <template v-for="(item, index) in lineupForm.polygonList">
              <el-tag :key="index" style="margin: 5px" closable size="mini" @close="handleClosePolygon(index)">{{
                  item[0]
                }}
              </el-tag>
            </template>
          </el-row>
          <!--创建直接显示的折线-->
          <el-row style="margin-top: 10px">
            <el-col :span="10">直接显示的折线</el-col>
            <el-col :span="10">
              <template v-if="lineupForm.isTempPolylineWithEndPoint">
                <el-button type="success" size="mini" @click="selectPolylineWithEndPointDone">创建完成</el-button>
              </template>
              <template v-else>
                <el-button type="primary" size="mini" @click="selectPolylineWithEndPoint">创建折线</el-button>
              </template>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <template v-if="lineupForm.polylineWithEndPointList.length!==0">
              <el-tag style="margin: 5px" closable size="mini" @close="handleClosePolylineWithEndPoint">{{
                  lineupForm.polylineWithEndPointList[0]
                }}
              </el-tag>
            </template>
          </el-row>
          <el-divider></el-divider>
          <el-row style="margin-top: 10px">
            <el-button type="primary" size="mini" @click="submitLineup">提交</el-button>
          </el-row>
        </el-card>

        <!--ID速查-->
        <el-card class="box-card" style="margin-top: 20px">
          <div slot="header" class="clearfix">
            <span style="font-weight: 600">ID速查</span>
          </div>
          <el-row>
            <el-col :span="4" style="margin-left: 10px;font-size: 18px">ID</el-col>
            <el-col :span="10">
              <el-input size="mini" v-model="inputID" @keyup.enter.native="submitInputId"></el-input>
            </el-col>
            <el-col :span="8">
              <el-button type="primary" size="mini" @click="submitInputId">确定</el-button>
            </el-col>
          </el-row>
        </el-card>
      </div>
      <!--中间地图主体-->
      <div class="mid-bar">
        <div id="map" ref="map" @mousemove="move">

        </div>
      </div>
      <!--右边-->
      <div class="right-bar">
        <!--网站公告-->
        <el-card class="box-card" :body-style="{ padding: '10px' }" style="margin-bottom: 20px"
                 v-if="$store.state.userinfo.isAdmin || showValAnnouncement">
          <div slot="header" class="clearfix">
            <span style="font-weight: 600">公告</span>
            <el-switch
                v-if="$store.state.userinfo.isAdmin"
                v-model="showValAnnouncement"
                @change="changeShowValAnnouncement"
                active-color="#13ce66"
                inactive-color="#dddfe6"
                style="float: right; padding: 3px 0"
            >
            </el-switch>
          </div>
          <template v-if="isEditingAnnouncement">
            <el-input
                @change="submitAnnouncement"
                type="textarea"
                autosize
                placeholder="请输入内容"
                v-model="valAnnouncement">
            </el-input>
          </template>
          <template v-else>
            <div style="white-space: pre-line; margin-left: 10px; margin-right: 10px; min-height: 12px"
                 @click="editAnnouncement">
              {{ valAnnouncement }}
            </div>
          </template>
        </el-card>
        <!--相关视频-->
        <el-card class="box-card" :body-style="{ padding: '10px' }">
          <div slot="header" class="clearfix">
          <span style="font-weight: 600">{{
              config.maps[this.selectMap].chTitle
            }} {{ config.agents[this.selectAgent].chTitle }} 教学视频</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="showRelatedVideo"
                       v-if="$store.state.userinfo.isAdmin">添加
            </el-button>
          </div>
          <div v-for="(item, index) in relatedVideoList" :key="index" class="text item">
            <a target="_blank" :href="item.link"><i class="iconfont icon-shipin" style="font-size: 14px"></i>{{
                item.title
              }}</a>
            <el-popconfirm
                v-if="$store.state.userinfo.isAdmin"
                title="确定删除吗？"
                @confirm="delRelatedVideo(item.id)"
            >
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </div>
        </el-card>

        <!--相关pov-->
        <el-card class="box-card" :body-style="{ padding: '10px' }" style="margin-top: 20px"
                 v-if="$store.state.userinfo.isAdmin">
          <div slot="header" class="clearfix">
          <span style="font-weight: 600">{{
              config.maps[this.selectMap].chTitle
            }} {{ config.agents[this.selectAgent].chTitle }} POV</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="showPov"
                       v-if="$store.state.userinfo.isAdmin">添加
            </el-button>
          </div>
          <div v-for="(item, index) in povList" :key="index" class="text item">
            <a target="_blank" :href="item.link"><i class="iconfont icon-shipin" style="font-size: 14px"></i>{{
                item.title
              }}</a>
            <el-popconfirm
                v-if="$store.state.userinfo.isAdmin"
                title="确定删除吗？"
                @confirm="delPov(item.id)"
            >
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </div>
        </el-card>
      </div>
    </template>
    <!--手机端页面-->
    <template v-else>
      <!--中间地图主体-->
      <div class="mid-bar-mobile" style="margin: 3px; padding: 0">
        <el-card class="box-card" :body-style="{ padding: '5px'}">

          <div class="map-agent" style="justify-content: start;">
            <div class="select-map" @click="showMapOptions"
                 style="margin-left: 10px;margin-right: 10px; width: 150px; height: 50px">
              <img :src="config.maps[this.selectMap].imgSrc" alt="">

              <div class="select-map-title">
                <!--                <div>-->
                <!--                  {{ config.maps[this.selectMap].title }}-->
                <!--                </div>-->
                <div>
                  {{ config.maps[this.selectMap].chTitle }}
                </div>
              </div>
            </div>
            <div class="select-agent" @click="showAgentOptions" :style="config.agents[this.selectAgent].background"
                 style="width: 50px">
              <img :src="config.agents[this.selectAgent].imgSrc" alt="">
              <div class="agent-title">{{ config.agents[this.selectAgent].chTitle }}</div>
            </div>
            <a href="#" @click="clickGameOption('cs2')" style="margin-left: 10px">切换至CS2点位</a>
          </div>
          <!--技能-->
          <div class="ability-options" style="margin-top: 10px">
            <template v-for="(value, name, index) in config.agents[selectAgent].ability">
              <template
                  v-if="mapToAgent[selectMap] && mapToAgent[selectMap][selectAgent] && mapToAgent[selectMap][selectAgent][name]">
                <el-badge :value="mapToAgent[selectMap][selectAgent][name]" class="item" :key="index">
                  <div class="ability-item" @click="clickAbilityOption(name)" style="width: 40px; height: 40px">
                    <img :src="value.imgSrc" alt="" v-bind:class="{'img-opacity2': selectAbility!=name}"
                         style="width: 25px; height: 25px">
                  </div>
                </el-badge>
              </template>
              <template v-else>
                <div class="ability-item" @click="clickAbilityOption(name)" :key="index"
                     style="width: 40px; height: 40px">
                  <img :src="value.imgSrc" alt="" v-bind:class="{'img-opacity2': selectAbility!=name}"
                       style="width: 25px; height: 25px">
                </div>
              </template>
            </template>
          </div>
          <!--其他-->
          <template v-if="mapAgentVideoList.length!==0">
            <el-divider content-position="left">其他</el-divider>
            <div v-for="(item, index) in mapAgentVideoList" :key="index" class="text item">
              <a target="_blank" :href="item.link"><i class="iconfont icon-shipin" style="font-size: 14px"></i>{{
                  item.title
                }}</a>
              <el-popconfirm
                  v-if="$store.state.userinfo.isAdmin"
                  title="确定删除吗？"
                  @confirm="delRelatedVideo(item.id)"
              >
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-card>
        <!--        <div id="map" ref="map" @mousemove="move" style="height: calc(100vh - 230px)">-->

        <!--        </div>-->
        <div id="map" ref="map" @mousemove="move" :style="'height:' + phoneMapHeight">

        </div>
        <el-card class="box-card" :body-style="{ padding: '5px'}" v-show="phoneDetailVisible">
          <div class="images" v-if="imageList.length !== 0">
            <template v-for="(item, index) in imageList">
              <!--              <el-image-->
              <!--                  style="margin-top: 10px"-->
              <!--                  :src="item"-->
              <!--                  :preview-src-list="imageList"-->
              <!--                  :key="index"-->
              <!--              >-->
              <!--              </el-image>-->
              <img
                  :src="item"
                  :key="index" width="100%">
            </template>

          </div>
          <div v-if="videoSrc" style="margin-top: 20px;font-size: 16px">
            <a :href="linkWithSecond" target="_blank">点击跳至B站查看原视频</a>
            <template v-if="discussData.bili_start_minute===0 && discussData.bili_start_second===0">
            </template>
            <template v-else>
              (精准空降{{ discussData.bili_start_minute }}分{{ discussData.bili_start_second }}秒)
            </template>
            <div class="biliVideo">
              <iframe :src="this.videoSrc" allowfullscreen="allowfullscreen"></iframe>
            </div>
          </div>
        </el-card>
      </div>
    </template>


    <!--地图选项-->
    <el-dialog
        title="选择地图"
        :visible.sync="mapOptionsVisible"
        :width="dialogWidth">
      <div class="map-options">
        <template v-for="(value, name, index) in config.maps">
          <template v-if="agentToMap[selectAgent] && agentToMap[selectAgent][name]">
            <el-badge :value="agentToMap[selectAgent][name]['count']" :key="index" class="agent-badge-item">
              <div class="map-item" @click="clickMapOption(name)">
                <img :src="value.imgSrc" alt="">
                <div class="map-title">
                  <div>{{ value.title }}</div>
                  <div>{{ value.chTitle }}</div>
                </div>
              </div>
            </el-badge>
          </template>
          <template v-else>
            <el-badge :key="index" class="agent-badge-item">
              <div class="map-item" :key="index" @click="clickMapOption(name)">
                <img :src="value.imgSrc" alt="">
                <div class="map-title">
                  <div>{{ value.title }}</div>
                  <div>{{ value.chTitle }}</div>
                </div>
              </div>
            </el-badge>
          </template>
        </template>
      </div>

    </el-dialog>
    <!--英雄选项-->
    <el-dialog
        title="选择英雄"
        :visible.sync="agentOptionsVisible"
        :width="dialogWidth">
      <div class="agent-options">
        <template v-for="(value, name, index) in config.agents">
          <template v-if="mapToAgent[selectMap] && mapToAgent[selectMap][name]">
            <el-badge :value="mapToAgent[selectMap][name]['count']" class="agent-badge-item" :key="index">
              <div class="agent-item" :style="value.background" @click="clickAgentOption(name)">
                <img :src="value.imgSrc" alt="">
                <div class="agent-title">{{ value.chTitle }}</div>
              </div>
            </el-badge>
          </template>
          <template v-else>
            <el-badge class="agent-badge-item" :key="index">
              <div class="agent-item" :style="value.background" @click="clickAgentOption(name)">
                <img :src="value.imgSrc" alt="">
                <div class="agent-title">{{ value.chTitle }}</div>
              </div>
            </el-badge>
          </template>

        </template>
      </div>

    </el-dialog>
    <!--悬浮框-->
    <div v-show="leafletTooltipVisible && !isPhoneWidth" class="hover_con" :style="positionStyle">
      {{ leafletTooltipContent }}
    </div>

    <!--相关视频添加-->
    <el-dialog
        title="教学视频添加"
        :visible.sync="relatedVideoVisible"
        width="40%">
      <el-checkbox v-model="relatedVideoCheck.map">地图</el-checkbox>
      <el-checkbox v-model="relatedVideoCheck.agent">英雄</el-checkbox>
      <el-form ref="form" label-width="40px" style="margin-top: 20px">
        <el-form-item label="标题">
          <el-input v-model="relatedVideoCheck.title"></el-input>
        </el-form-item>
        <el-form-item label="链接">
          <el-input v-model="relatedVideoCheck.link"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submitRelatedVideo">提交</el-button>
    </el-dialog>

    <!--相关视频添加-->
    <el-dialog
        title="Pov添加"
        :visible.sync="povVisible"
        width="40%">
      <el-form ref="form" label-width="50px" style="margin-top: 20px">
        <el-form-item label="up">
          <el-radio-group v-model="povForm.biliUpName">
            <template v-for="(value, index) in config.povUpNameList">
              <el-radio :label="value" :key="index"></el-radio>
            </template>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="povForm.title"></el-input>
        </el-form-item>
        <el-form-item label="链接">
          <el-input v-model="povForm.link"></el-input>
        </el-form-item>
        <el-form-item label="日期">
          <el-col :span="11">
            <el-date-picker type="date" placeholder="日期" v-model="povForm.uploadDate"
                            style="width: 100%;"></el-date-picker>
          </el-col>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submitPov">提交</el-button>
    </el-dialog>

    <el-dialog
        title="选择 无畏契约/CS2"
        :visible.sync="gameSelectVisible"
        :close-on-click-modal="false"
        :width="dialogWidth">
      <div class="out-parent">
        <div class="share-item" @click="clickGameOption('val')">
          <!--          <span class="demonstration">无畏契约</span>-->
          <el-image :src="config.game.val.logoSrc"
                    style="width: 100%"
          >
          </el-image>
        </div>
        <div class="share-item" @click="clickGameOption('cs2')">
          <!--          <span class="demonstration">CS2</span>-->
          <el-image :src="config.game.cs2.logoSrc"
                    width="100%"
          ></el-image>
        </div>

      </div>
      <span slot="footer" class="dialog-footer">

  </span>

    </el-dialog>
  </div>
</template>

<script>
import {config} from "@/common/config";
import {distance} from "@/common/comm";
import L from 'leaflet'
import axios from 'axios'
import {biliLinkToIframe} from "@/common/bili";
import {imgNameToUrl} from "@/common/comm";
import store from "@/store";

let map
let startPoint //站点
let endPointGroup //落点
let tempRangePolygon //暂时的范围
let tempPolylineWithEndPoint //暂时的折线
let rangePolygonList
let mouseOverLayerGroup  // 鼠标移入时显示的东西
let endCircleLayerGroup // 初始化所有点位
let multipleLayerGroup // 点击聚合点位时显示的东西
const PHONE_WIDTH = 768
export default {
  name: "Home",
  data() {
    return {
      gameSelectVisible: false,
      isPhoneWidth: false,
      source: null, // 存放取消的请求方法
      selectMap: localStorage.getItem('selectMap') ? localStorage.getItem('selectMap') : 'haven',
      selectAgent: localStorage.getItem('selectAgent') ? localStorage.getItem('selectAgent') : 'sova',
      selectAbility: localStorage.getItem('selectAbility') ? localStorage.getItem('selectAbility') : 4,
      config,
      mapOptionsVisible: false,
      agentOptionsVisible: false,
      lineupForm: {
        blogId: null,
        title: '',
        startPoint: null,
        endPoint: null,
        //添加一个范围 sova的箭，fade的眼
        isTempPolygon: false,
        tempPolygonList: [],
        polygonList: [],
        // 添加一条折线，此折线会直接显示并且以endPoint为起点， cypher的拌线，viper的墙
        isTempPolylineWithEndPoint: false,
        polylineWithEndPointList: [],
        //额外的折线组 viper烟墙可能穿过传送门，比较特殊，暂时不能从地图直接添加，可在数据库修改数据
        isTempExtraPolylineList: false,
        tempExtraPolylineList: [],
        extraPolylineList: [],
      },
      positionStyle: {top: '0px', left: '0px'},
      leafletTooltipVisible: false,
      leafletTooltipContent: '',

      relatedVideoVisible: false,
      relatedVideoCheck: {
        map: false,
        agent: false,
        title: '',
        link: '',
      },

      povVisible: false,
      povForm: {
        biliUpName: '',
        title: '',
        link: '',
        uploadDate: '',
      },

      relatedVideoList: [], //相关视频列表
      mapAgentVideoList: [], // map-agent-video
      povList: [], //相关pov列表
      agentToMap: {},
      mapToAgent: {},
      // 正在进行地图选点
      isSelectPointing: false,
      // 手机端显示图片和视频
      phoneDetailVisible: false,
      imageList: [],
      videoSrc: '',
      linkWithSecond: '',
      discussData: {},
      // id速查：
      inputID: '',
      //公告
      showValAnnouncement: false,
      valAnnouncement: '',
      isEditingAnnouncement: false,
      // 手机端的地图高度
      phoneMapHeight: '60vh',
    }
  },
  computed: {
    dialogWidth() {
      return this.isPhoneWidth ? '70%' : '30%'
    }
  },
  created() {
    this.handleWindowWidth()
    // window.addEventListener('resize', this.handleWindowWidth)
    window.addEventListener('resize', this.handlePhoneMapWidth)
  },
  mounted() {
    if (localStorage.getItem('currentPage')) { // 已经访问过网站
      if (localStorage.getItem('currentPage') === 'cs2') {
        this.$router.push("/cs2");
      }
    } else { // 从未访问过网站
      this.gameSelectVisible = true
    }
    store.commit('setCurrentPage', 'val')
    localStorage.setItem('currentPage', 'val')
    this.initMap(this.selectMap)
    this.getConfig()
    this.selectChange()
    this.getLineupCount()
    this.handleSetLineup() // 添加新的lineup时处理url参数
  },
  destroyed() {
    // window.addEventListener('resize', this.handleWindowWidth)
    document.removeEventListener('click', this.checkClick);
  },
  watch: {
    mapOptionsVisible: {
      handler(newVal, oldVal) {
        if (newVal) {
          setTimeout(() => {
            document.addEventListener('click', this.checkClick);
          }, 0)
        } else {
          document.removeEventListener('click', this.checkClick);
        }
      },
    }
  },
  methods: {
    handleWindowWidth() {
      this.isPhoneWidth = window.innerWidth <= PHONE_WIDTH;
    },
    handlePhoneMapWidth() {
      if (window.innerWidth < window.innerHeight) {
        this.phoneMapHeight = '60vh'
      } else {
        this.phoneMapHeight = '100vh'
      }
    },
    initMap(selectMap) {
      var _this = this
      if (map) {
        map.remove()
      }
      const pngHeight = 2000
      const mapDivHeight = this.$refs.map.offsetHeight
      const mapDivWidth = this.$refs.map.offsetWidth
      let mapZoom
      if (mapDivHeight > mapDivWidth) {
        mapZoom = Math.log2(mapDivWidth / pngHeight)
      } else {
        mapZoom = Math.log2(mapDivHeight * 0.95 / pngHeight)
      }
      map = L.map('map', {
        crs: L.CRS.Simple,
        zoom: mapZoom,
        zoomSnap: 0.1,
        attributionControl: false,
      });
      var bounds = [[0, 0], [2000, 2000]]
      L.imageOverlay(config.maps[selectMap].radarImgSrc, bounds).addTo(map)
      map.fitBounds(bounds);
      map.setView([1000, 1000], mapZoom)
      // map.on('click', function (e) {
      //   alert(e.latlng);
      // });

      // this.testLeaflet()
    },
    testLeaflet() {
      const x = 1321
      const y = 493
      const imgSize = 40
      const circleRadius = 30
      const mouseOverRadius = 40
      const difficultyCircleRadius = 6
      //加图片
      let pngBounds = [[x - imgSize / 2, y - imgSize / 2], [x + imgSize / 2, y + imgSize / 2]]
      let png = L.imageOverlay(config.agents.astra.ability["1"].imgSrc, pngBounds, {
        zIndex: 401,
        interactive: true,
        extraInfo: {
          x: 1321,
          y: 493,
        },
      })

      png.on('mouseover', circleMouseOver)
      png.on('mouseout', circleMouseOut)
      png.addTo(map)
      //加底部圆
      //鼠标移入操作
      function circleMouseOver(e) {
        let x = e.sourceTarget.options.extraInfo.x
        let y = e.sourceTarget.options.extraInfo.y
        let circle = L.circle([x, y], {
          radius: mouseOverRadius,
          weight: 1,
        })
        let arr = []
        arr.push(circle)
        const latlngs = [[1631, 199], [1631, 447], [1369, 447], [1366, 204]];
        var polygon = L.polygon(latlngs, {color: 'red'})
        arr.push(polygon)
        mouseOverLayerGroup = L.layerGroup(arr)
        mouseOverLayerGroup.addTo(map)
      }

      function circleMouseOut(e) {
        if (mouseOverLayerGroup) {
          mouseOverLayerGroup.remove()
        }
      }

      let circle = L.circle([x, y], {
        radius: circleRadius,
        fillColor: '#C8D0CF',
        weight: 0,
        extraInfo: {
          x: 1321,
          y: 493,
        },
      }).addTo(map);
      circle.on('mouseover', circleMouseOver)
      circle.on('mouseout', circleMouseOut)
      //加显示难度的绿圈
      L.circle([x - circleRadius * 0.75, y + circleRadius * 0.75], {
        radius: difficultyCircleRadius,
        fillColor: '#70bc4f',
        fillOpacity: 1,
        weight: 0,
      }).addTo(map);
    },
    showMapOptions() {
      this.mapOptionsVisible = true
    },
    showAgentOptions() {
      this.agentOptionsVisible = true
    },
    hideMapOptions() {
      this.mapOptionsVisible = false
    },
    checkClick(event) {
      let dom = this.$refs.mapOptions
      if (!dom.contains(event.target)) {
        if (this.mapOptionsVisible) {
          this.hideMapOptions()
          document.removeEventListener('click', this.checkClick);
        }
      } else {
        console.log('在范围内')
      }
    },
    clickMapOption(name) {
      this.selectMap = name
      localStorage.setItem('selectMap', name)
      this.hideMapOptions()
      this.initMap(this.selectMap)
      this.selectChange()
    },
    clickAgentOption(name) {
      this.selectAgent = name
      this.agentOptionsVisible = false
      localStorage.setItem('selectAgent', name)
      this.selectChange()
    },
    clickAbilityOption(name) {
      this.selectAbility = name
      localStorage.setItem('selectAbility', name)
      this.selectChange()
    },
    handleSetLineup() {
      if (this.$route.query.blogid) {
        this.lineupForm.blogId = this.$route.query.blogid
      }
    },
    selectPoint(type) {
      if (type === 'start') {
        this.$message({
          message: '点击地图位置，选择人物站点',
          duration: 2000,
        })
      } else {
        this.$message({
          message: '点击地图位置，选择道具落点',
          duration: 2000,
        })
      }
      let _this = this
      this.isSelectPointing = true
      map.on('click', function (e) {
        alert(e.latlng)
        const x = Math.round(e.latlng.lat)
        const y = Math.round(e.latlng.lng)
        if (type === 'start') { // 选择站点
          if (startPoint) {
            startPoint.remove()
          }
          startPoint = L.circle([x, y], {
            radius: config.leaflet.addLineup.point.radius,
            fillColor: config.leaflet.addLineup.point.startPointFillColor,
            weight: 0,
            fillOpacity: 1,
          }).addTo(map);
          _this.lineupForm.startPoint = [x, y]
        } else { //选择落点
          if (endPointGroup) {
            endPointGroup.remove()
          }
          let arr = []
          let endPoint = L.circle([x, y], {
            radius: config.leaflet.addLineup.point.radius,
            fillColor: config.leaflet.addLineup.point.endPointFillColor,
            weight: 0,
            fillOpacity: 1,
          })
          _this.lineupForm.endPoint = [x, y]
          arr.push(endPoint)

          //技能图片
          let imgSize = config.leaflet.showLineup.abilityImgSize
          let pngBounds = [[x - imgSize / 2, y - imgSize / 2], [x + imgSize / 2, y + imgSize / 2]]
          let png = L.imageOverlay(config.agents[_this.selectAgent].ability[_this.selectAbility].imgSrc, pngBounds, {
            zIndex: 401,
            interactive: true,
          })
          arr.push(png)

          //技能透明圆圈
          let endCircle = L.circle([x, y], {
            radius: config.leaflet.showLineup.endCircle.radius,
            fillColor: config.leaflet.showLineup.endCircle.fillColor,
            weight: 0,
          })
          arr.push(endCircle)
          endPointGroup = L.layerGroup(arr)
          endPointGroup.addTo(map)
        }


        map.off('click')
        this.isSelectPointing = false
      });
    },
    selectRange() {
      let _this = this
      this.lineupForm.isTempPolygon = true
      this.lineupForm.tempPolygonList = []
      map.on('click', function (e) {
        const x = Math.round(e.latlng.lat)
        const y = Math.round(e.latlng.lng)
        _this.lineupForm.tempPolygonList.push([x, y])
        if (tempRangePolygon) {
          tempRangePolygon.remove()
        }
        tempRangePolygon = L.polygon(_this.lineupForm.tempPolygonList, {
          color: config.leaflet.addLineup.polygon.color,
          weight: config.leaflet.addLineup.polygon.weight,
        }).addTo(map)
      })
    },
    selectRangeDone() {
      this.lineupForm.polygonList.push(this.lineupForm.tempPolygonList)
      this.showPolygonList()
      map.off('click')
      this.lineupForm.tempPolygonList = []
      this.lineupForm.isTempPolygon = false
      if (tempRangePolygon) {
        tempRangePolygon.remove()
      }
    },
    handleClosePolygon(index) {
      this.lineupForm.polygonList.splice(index, 1)
      this.showPolygonList()
    },
    showPolygonList() {
      if (rangePolygonList) {
        rangePolygonList.remove()
      }
      let arr = []
      for (let i = 0; i < this.lineupForm.polygonList.length; i++) {
        let polygon = L.polygon(this.lineupForm.polygonList[i], {
          color: config.leaflet.addLineup.polygon.color,
          weight: config.leaflet.addLineup.polygon.weight,
        })
        arr.push(polygon)
      }
      rangePolygonList = L.layerGroup(arr)
      rangePolygonList.addTo(map)
    },
    submitLineup() {
      axios({
        method: 'post',
        url: '/api/blogs/submit_lineup',
        data: Object.assign({}, this.lineupForm, {
          map_name: this.selectMap,
          agent_name: this.selectAgent,
          ability: this.selectAbility
        })
      }).then(r => {
        if (r.data.code === 0) {
          this.lineupForm = {
            blogId: null,
            title: '',
            startPoint: null,
            endPoint: null,

            isTempPolygon: false,
            tempPolygonList: [],
            polygonList: [],

            isTempPolylineWithEndPoint: false,
            polylineWithEndPointList: [],
            extraPolylineList: [],
          }
          this.$router.push({query: {}});
          this.selectChange()
          this.isSelectPointing = false
          this.$message({
            message: '提交成功',
            type: 'success'
          });
        } else {
          this.$message.error(r.data.msg);
        }
      })
    },
    cancelRequest() {
      if (typeof this.source === 'function') {
        this.source('终止请求')
      }
    },
    selectChange() {
      const _this = this;
      this.cancelRequest();
      axios({
        method: 'get',
        url: '/api/blogs/get_lineup_list',
        params: {
          map_name: this.selectMap,
          agent_name: this.selectAgent,
          ability: this.selectAbility
        },
        cancelToken: new axios.CancelToken(function executor(c) {
          _this.source = c
        })
      }).then(r => {
        if (r.data.code === 0) {
          this.initLineup(r.data.list)
          this.initRelatedVideo(r.data.related_video_list)
          this.initMapAgentVideo(r.data.map_agent_video_list)
          this.initPov(r.data.pov_list)
        } else {
          this.$message.error('加载失败');
        }
      })
    },
    initLineup(dataArr) {
      let _this = this
      if (endCircleLayerGroup) {
        endCircleLayerGroup.remove()
      }
      if (multipleLayerGroup) {
        multipleLayerGroup.remove()
      }
      let togetherList = []
      for (let i = 0; i < dataArr.length; i++) {
        if (!dataArr[i]) {
          continue
        }

        let lineupObj = dataArr[i]
        let endX = lineupObj.leaflet_params.endPoint[0]
        let endY = lineupObj.leaflet_params.endPoint[1]
        let togetherObj = {
          count: 1,
          endX: endX,
          endY: endY,
          list: [lineupObj],
          agent: lineupObj.agent_name,
          ability: lineupObj.ability,
        }
        for (let j = i + 1; j < dataArr.length; j++) {
          if (!dataArr[j]) {
            continue
          }
          let tmpObj = dataArr[j]
          let tmpEndX = tmpObj.leaflet_params.endPoint[0]
          let tmpEndY = tmpObj.leaflet_params.endPoint[1]
          if (distance(dataArr[i], dataArr[j]) < config.leaflet.showLineup.togetherDistance) {
            togetherObj.count += 1
            togetherObj.endX = (togetherObj.endX + tmpEndX) / 2
            togetherObj.endY = (togetherObj.endY + tmpEndY) / 2
            togetherObj.list.push(dataArr[j])
            dataArr[j] = null
          }
        }
        togetherList.push(togetherObj)
      }

      let arr = []
      for (let i = 0; i < togetherList.length; i++) {
        let togetherObj = togetherList[i]
        // 技能图片
        const endX = togetherObj.endX
        const endY = togetherObj.endY
        let imgSize = config.leaflet.showLineup.abilityImgSize
        let agent = togetherObj.agent
        let ability = togetherObj.ability
        let pngBounds = [[endX - imgSize / 2, endY - imgSize / 2], [endX + imgSize / 2, endY + imgSize / 2]]
        let png = L.imageOverlay(config.agents[agent].ability[ability].imgSrc, pngBounds, {
          zIndex: 401,
          interactive: true,
          lineupList: togetherObj.list,
          togetherObj: togetherObj
        })
        png.on('mouseover', endCircleMouseOver)
        png.on('mouseout', endCircleMouseOut)
        png.on('click', endCircleClick)
        arr.push(png)
        // 技能透明圆圈
        let endCircle = L.circle([endX, endY], {
          radius: config.leaflet.showLineup.endCircle.radius,
          fillColor: config.leaflet.showLineup.endCircle.fillColor,
          weight: 0,
          lineupList: togetherObj.list,
          togetherObj: togetherObj
        })
        endCircle.on('mouseover', endCircleMouseOver)
        endCircle.on('mouseout', endCircleMouseOut)
        endCircle.on('click', endCircleClick)
        arr.push(endCircle)
        // 直接显示的折线 viper烟墙 零的拌线, 这里有个问题，如果两个落点相同怎么处理, 目前直接显示第一个
        let firstLineupObj = togetherObj.list[0]
        if (firstLineupObj.leaflet_params.polylineWithEndPointList) {
          let tmpList = [firstLineupObj.leaflet_params.endPoint].concat(firstLineupObj.leaflet_params.polylineWithEndPointList)
          let polylineWithEndPoint = L.polyline(tmpList, {
            color: config.leaflet.showLineup.polylineWithEndPoint.color,
            weight: config.leaflet.showLineup.polylineWithEndPoint.weight
          })
          arr.push(polylineWithEndPoint)
        }
        // 额外的折线组，viper烟墙通过传送门时的情况
        if (firstLineupObj.leaflet_params.extraPolylineList) {
          let extraPolylineList = firstLineupObj.leaflet_params.extraPolylineList
          for (let i = 0; i < extraPolylineList.length; i++) {
            let polyline = L.polyline(extraPolylineList[i], {
              color: config.leaflet.showLineup.polylineWithEndPoint.color,
              weight: config.leaflet.showLineup.polylineWithEndPoint.weight
            })
            arr.push(polyline)
          }
        }
      }
      endCircleLayerGroup = L.layerGroup(arr)
      endCircleLayerGroup.addTo(map)

      // 鼠标移入技能图片事件
      function endCircleMouseOver(e) {
        //加入到mouseover组显示
        let arr = []
        //显示一个小蓝圈
        let endX = e.sourceTarget.options.togetherObj.endX
        let endY = e.sourceTarget.options.togetherObj.endY
        let circle = L.circle([endX, endY], {
          radius: config.leaflet.showLineup.endCircleMouseOver.radius,
          weight: 1,
        })

        let lineupList = e.sourceTarget.options.lineupList
        //悬浮框
        if (lineupList.length === 1) {
          _this.leafletTooltipVisible = true
          _this.leafletTooltipContent = lineupList[0].lineup_title
        }

        for (let i = 0; i < lineupList.length; i++) {
          let lineupObj = lineupList[i]
          arr.push(circle)
          //显示人物站点
          if (lineupObj.leaflet_params.startPoint) {
            let startX = lineupObj.leaflet_params.startPoint[0]
            let startY = lineupObj.leaflet_params.startPoint[1]
            //显示起始点的一条直线
            let startEndLine = L.polyline([[startX, startY], [endX, endY]], {
              color: config.leaflet.showLineup.startEndLine.color,
              weight: config.leaflet.showLineup.startEndLine.weight,
            });
            arr.push(startEndLine)
            //显示人物图片
            let imgSize = config.leaflet.showLineup.agentImgSize
            let agent = lineupObj.agent_name
            let pngBounds = [[startX - imgSize / 2, startY - imgSize / 2], [startX + imgSize / 2, startY + imgSize / 2]]
            let agentPng = L.imageOverlay(config.agents[agent].imgSrc, pngBounds, {
              zIndex: 401,
              interactive: true,
            })
            arr.push(agentPng)
            //人物背景
            let agentCircle = L.circle([startX, startY], {
              radius: config.leaflet.showLineup.agentCircle.radius,
              weight: config.leaflet.showLineup.agentCircle.weight,
              fillOpacity: config.leaflet.showLineup.agentCircle.fillOpacity,
              fillColor: config.agents[agent].color
            })
            arr.push(agentCircle)
          }
          // 有范围的处理   先不显示sova箭的范围，先显示一下jett的
          if (lineupObj.leaflet_params.polygonList && _this.selectAgent === 'jett') {
            let polygonList = lineupObj.leaflet_params.polygonList
            for (let i = 0; i < polygonList.length; i++) {
              let polygon = L.polygon(polygonList[i], {
                color: config.leaflet.showLineup.polygon.color,
                weight: config.leaflet.showLineup.polygon.weight,
                fillOpacity: config.leaflet.showLineup.polygon.fillOpacity,
              })
              arr.push(polygon)
            }
          }
          // 直接显示的折线, 这里有个问题，如果两个落点相同怎么处理, 目前直接显示第一个
          if (lineupObj.leaflet_params.polylineWithEndPointList) {
            let tmpList = [lineupObj.leaflet_params.endPoint].concat(lineupObj.leaflet_params.polylineWithEndPointList)
            let polylineWithEndPoint = L.polyline(tmpList, {
              color: config.leaflet.mouseover.polylineWithEndPoint.color,
              weight: config.leaflet.mouseover.polylineWithEndPoint.weight
            })
            arr.push(polylineWithEndPoint)
          }
          // 额外的折线组，viper的传送门烟墙
          if (lineupObj.leaflet_params.extraPolylineList) {
            let extraPolylineList = lineupObj.leaflet_params.extraPolylineList
            for (let i = 0; i < extraPolylineList.length; i++) {
              let polyline = L.polyline(extraPolylineList[i], {
                color: config.leaflet.mouseover.polylineWithEndPoint.color,
                weight: config.leaflet.mouseover.polylineWithEndPoint.weight
              })
              arr.push(polyline)
            }
          }
        }
        //mouseover组显示
        mouseOverLayerGroup = L.layerGroup(arr)
        mouseOverLayerGroup.addTo(map)
      }

      // 鼠标移出技能图片事件
      function endCircleMouseOut() {
        //悬浮框
        _this.leafletTooltipVisible = false
        if (mouseOverLayerGroup) {
          mouseOverLayerGroup.remove()
        }
      }

      //鼠标点击技能图片事件
      function endCircleClick(e) {
        _this.leafletTooltipVisible = false
        if (_this.isSelectPointing) {
          //如果正在地图选点，不触发点击事件
          return
        }
        if (multipleLayerGroup) {
          multipleLayerGroup.remove()
        }

        let lineupList = e.sourceTarget.options.lineupList
        if (lineupList.length === 1) {
          let lineupObj = lineupList[0]
          if (_this.isPhoneWidth) { // 手机端 直接显示在下方
            _this.handlePhoneCircle(lineupObj.blog_id)
          } else { //PC端 打开一个新页面
            window.open('/discuss/' + lineupObj.blog_id)
          }
        }

        let arr = []
        if (lineupList.length > 1) {
          _this.$message('多个站位，点击站位查看对应教学');
          //小蓝圈
          //显示一个小蓝圈
          let endX = e.sourceTarget.options.togetherObj.endX
          let endY = e.sourceTarget.options.togetherObj.endY
          let circle = L.circle([endX, endY], {
            radius: config.leaflet.showLineup.endCircleMouseOver.radius,
            weight: 1,
          })
          arr.push(circle)

          for (let i = 0; i < lineupList.length; i++) {
            let lineupObj = lineupList[i]
            let startX = lineupObj.leaflet_params.startPoint[0]
            let startY = lineupObj.leaflet_params.startPoint[1]
            let endX = lineupObj.leaflet_params.endPoint[0]
            let endY = lineupObj.leaflet_params.endPoint[1]
            //显示起始点的一条直线
            let startEndLine = L.polyline([[startX, startY], [endX, endY]], {
              color: config.leaflet.showLineup.startEndLine.color,
              weight: config.leaflet.showLineup.startEndLine.weight,
            });
            arr.push(startEndLine)
            //显示人物图片
            let imgSize = config.leaflet.showLineup.agentImgSize
            let agent = lineupObj.agent_name
            let pngBounds = [[startX - imgSize / 2, startY - imgSize / 2], [startX + imgSize / 2, startY + imgSize / 2]]
            let agentPng = L.imageOverlay(config.agents[agent].imgSrc, pngBounds, {
              zIndex: 401,
              interactive: true,
              lineupList: [lineupObj]
            })
            agentPng.on('mouseover', agentCircleMouseOver)
            agentPng.on('mouseout', agentCircleMouseOut)
            agentPng.on('click', endCircleClick)
            arr.push(agentPng)
            //人物背景
            let agentCircle = L.circle([startX, startY], {
              radius: config.leaflet.showLineup.agentCircle.radius,
              weight: config.leaflet.showLineup.agentCircle.weight,
              fillOpacity: config.leaflet.showLineup.agentCircle.fillOpacity,
              fillColor: config.agents[agent].color,
              lineupList: [lineupObj]
            })
            agentCircle.on('mouseover', agentCircleMouseOver)
            agentCircle.on('mouseout', agentCircleMouseOut)
            agentCircle.on('click', endCircleClick)
            arr.push(agentCircle)
          }
        }
        multipleLayerGroup = L.layerGroup(arr)
        multipleLayerGroup.addTo(map)
      }

      function agentCircleMouseOver(e) {
        // 相同落点的道具点击之后，鼠标移入站点时悬浮框出现
        let lineupList = e.sourceTarget.options.lineupList
        if (lineupList.length === 1) {
          _this.leafletTooltipVisible = true
          _this.leafletTooltipContent = lineupList[0].lineup_title
        }
      }

      function agentCircleMouseOut() {
        _this.leafletTooltipVisible = false
      }
    },
    initRelatedVideo(dataArr) {
      this.relatedVideoList = dataArr
    },
    initMapAgentVideo(dataArr) {
      this.mapAgentVideoList = dataArr
    },
    initPov(dataArr) {
      this.povList = dataArr
    },
    move(event) {
      const x = event.pageX + 15 + 'px'
      const y = event.pageY + 10 + 'px'
      this.positionStyle = {top: y, left: x}
    },
    showRelatedVideo() {
      this.relatedVideoVisible = true
    },
    showPov() {
      this.povVisible = true
    },
    submitRelatedVideo() {
      axios({
        method: 'post',
        url: '/api/blogs/submit_related_video',
        data: {
          map_name: this.selectMap,
          agent_name: this.selectAgent,
          relatedVideo: this.relatedVideoCheck,
        }
      }).then(r => {
        if (r.data.code === 0) {
          this.selectChange()
          this.relatedVideoCheck = {
            map: false,
            agent: false,
            title: '',
            link: '',
          }
          this.relatedVideoVisible = false
          this.$message({
            message: '提交成功',
            type: 'success'
          });
        }
      })
    },
    submitPov() {
      try {
        const videoUrl = this.povForm.link
        const bv = videoUrl.match(/(BV.*?).{10}/)[0]
        let p = null;
        try {
          p = videoUrl.match(videoUrl.match(/p=(\d+)/)[1]);
        } catch (error) {
          p = null
        }
        this.povForm.link = 'https://www.bilibili.com/video/' + bv
        if (p) {
          this.povForm.link += "?p=" + p
        }
      } catch (e) {
        this.$message.error("视频链接错误");
        return
      }
      axios({
        method: 'post',
        url: '/api/blogs/submit_pov',
        data: {
          map_name: this.selectMap,
          agent_name: this.selectAgent,
          pov_form: this.povForm
        }
      }).then(r => {
        if (r.data.code === 0) {
          this.selectChange()
          this.povForm.title = ''
          this.povForm.link = ''
          this.$message({
            message: '提交成功',
            type: 'success'
          });
        } else {
          this.$message.error(r.data.msg);
        }
      })
    },
    delRelatedVideo(itemId) {
      axios({
        method: 'post',
        url: '/api/blogs/del_related_video',
        data: {
          obj_id: itemId,
        }
      }).then(r => {
        if (r.data.code === 0) {
          this.selectChange()
          this.$message({
            message: '删除成功',
            type: 'success'
          });
        }
      })
    },
    delPov(itemId) {
      axios({
        method: 'post',
        url: '/api/blogs/del_pov',
        data: {
          obj_id: itemId,
        }
      }).then(r => {
        if (r.data.code === 0) {
          this.selectChange()
          this.$message({
            message: '删除成功',
            type: 'success'
          });
        }
      })
    },
    selectPolylineWithEndPoint() {
      if (!this.lineupForm.endPoint) {
        this.$message.error('请先选择落点');
        return
      }
      let _this = this
      this.lineupForm.isTempPolylineWithEndPoint = true
      this.lineupForm.polylineWithEndPointList = []
      map.on('click', function (e) {
        const x = Math.round(e.latlng.lat)
        const y = Math.round(e.latlng.lng)
        _this.lineupForm.polylineWithEndPointList.push([x, y])
        if (tempPolylineWithEndPoint) {
          tempPolylineWithEndPoint.remove()
        }
        let tmpList = [_this.lineupForm.endPoint].concat(_this.lineupForm.polylineWithEndPointList)
        tempPolylineWithEndPoint = L.polyline(tmpList, {color: 'red', weight: 2}).addTo(map)
      })
    },
    selectPolylineWithEndPointDone() {
      map.off('click')
      this.lineupForm.isTempPolylineWithEndPoint = false
    },
    handleClosePolylineWithEndPoint() {
      this.lineupForm.polylineWithEndPointList = []
      if (tempPolylineWithEndPoint) {
        tempPolylineWithEndPoint.remove()
      }
    },
    getLineupCount() {
      axios({
        method: 'get',
        url: '/api/blogs/get_lineup_count',
      }).then(r => {
        if (r.data.code === 0) {
          this.agentToMap = r.data.agent_to_map
          this.mapToAgent = r.data.map_to_agent
          for (let i in this.agentToMap) {
            for (let j in this.agentToMap[i]) {
              let count = 0
              for (let k in this.agentToMap[i][j]) {
                count += this.agentToMap[i][j][k]
              }
              this.agentToMap[i][j]['count'] = count
            }
          }
          for (let i in this.mapToAgent) {
            for (let j in this.mapToAgent[i]) {
              let count = 0
              for (let k in this.mapToAgent[i][j]) {
                count += this.mapToAgent[i][j][k]
              }
              this.mapToAgent[i][j]['count'] = count
            }
          }
        }
      })
    },
    handlePhoneCircle(blog_id) {
      this.phoneDetailVisible = true
      axios({
        method: 'get',
        url: '/api/blogs/get_discuss_detail',
        params: {
          blog_id: blog_id,
        }
      }).then(r => {
        this.discussData = r.data.detail
        this.imageList = []
        // 处理图片
        for (let i = 0; i < this.discussData.images.length; i++) {
          // 腾讯云COS
          //this.imageList.push(`https://lkval-1259337012.cos.ap-nanjing.myqcloud.com/lkval/main/${this.discussData.images[i].split('.')[0] + '.' + 'webp'}`)
          this.imageList.push(imgNameToUrl(this.discussData.images[i]))
        }
        // 处理视频
        if (this.discussData.bili_link) {
          const [videoSrc, linkWithSecond] = biliLinkToIframe(this.discussData.bili_link, this.discussData.bili_start_minute, this.discussData.bili_start_second, this.discussData.bili_iframe)
          this.videoSrc = videoSrc
          this.linkWithSecond = linkWithSecond
        }
        this.$message({
          message: '加载成功',
          type: 'success',
          duration: 500,
        });
      })
    },
    submitInputId() {
      let inputID = this.inputID.trim()
      let reg = /^[0-9]+$/;
      if (reg.test(inputID)) {
        window.open('/discuss/' + inputID)
      } else {
        this.$message.error('ID为数字');
      }
    },
    editAnnouncement() {
      if (this.$store.state.userinfo.isAdmin) {
        this.isEditingAnnouncement = true
      }
    },
    submitAnnouncement() {
      axios({
        method: 'post',
        url: '/api/blogs/edit_config',
        data: {
          name: 'valAnnouncement',
          value: this.valAnnouncement,
        }
      }).then(r => {
        if (r.data.code === 0) {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        }
      })
      this.isEditingAnnouncement = false
    },
    changeShowValAnnouncement(value) {
      axios({
        method: 'post',
        url: '/api/blogs/edit_config',
        data: {
          name: 'showValAnnouncement',
          value: value.toString()
        }
      }).then(r => {
        if (r.data.code === 0) {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        }
      })
    },
    getConfig() {
      axios({
        method: 'get',
        url: '/api/blogs/get_all_config',
      }).then(r => {
        if (r.data.code === 0) {
          let config = r.data.config
          if (config.valAnnouncement) {
            this.valAnnouncement = config.valAnnouncement
          }
          if (config.showValAnnouncement) {
            this.showValAnnouncement = config.showValAnnouncement === "true"
          }
        }
      })
    },
    clickGameOption(game) {
      if (game === 'cs2') {
        this.$router.push("/cs2");
      }
      this.gameSelectVisible = false
    }
  }
}
</script>

<style scoped>
.main {
  display: flex;
  height: calc(100vh - 52px);
  background: #f1f2f5;
  justify-content: center;

}

.left-bar {
  width: 300px;
  padding: 10px;
}

.mid-bar {
  width: calc(100vh - 52px);
  height: calc(100vh - 52px);
  padding: 10px 0;
}

.mid-bar-mobile {
  width: 100%;
  height: calc(100vh - 52px);
  padding: 10px 0;
}

#map {
  height: 100%;
  background-color: #3f474f;
  border-radius: 10px;
}

.right-bar {
  width: 300px;
  padding: 10px;
}

/*.left-bar {*/
/*  width: 400px;*/
/*  !*padding: 20px;*!*/
/*  height: calc(100vh - 52px);*/
/*  background: #152036;*/
/*  overflow: auto;*/
/*  position: relative;*/
/*}*/

/*.right-bar {*/
/*  top: 52px;*/
/*  left: 400px;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  position: absolute;*/
/*  width: auto;*/
/*  height: auto;*/
/*}*/

/*#map {*/
/*  height: 100%;*/
/*  background-color: #3f474f;*/
/*}*/

.filter-blur-wrapper {
  height: 100%;
}

.filter-blur {

  filter: blur(5px);
}

.selected-map-title {
  position: absolute;
  bottom: 10%;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 30px;
  font-weight: 1000
}

.selected-map {
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  /*filter: blur(5px);*/
}

.map-options {
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.map-options .map-item {
  width: 150px;
  margin: 3px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
}

.map-options .map-item img {
  width: 100%;
  transition: transform 0.3s;
}

.map-options .map-item:hover > img {
  transform: scale(1.2);
}

.map-options .map-item .map-title {
  position: absolute;
  bottom: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-weight: 600;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.selected-map img {
  transition: transform 0.3s;
  width: 100%;
}

.selected-map:hover > img {
  transform: scale(1.2);
}

.agent-options {
  margin: 10px;
  display: flex;
  flex-flow: wrap;
  /*justify-content: space-evenly;*/
  align-items: center;
}

.agent-options .agent-item {
  margin: 5px;
  width: 70px;
  height: 70px;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.agent-badge-item {
  margin: 10px;
}

.agent-options .agent-item img {
  width: 100%;
  transition: transform 0.3s;
}

.agent-options .agent-item:hover > img {
  transform: scale(1.2);
}

.img-opacity5 {
  opacity: 0.5;
}

.img-opacity2 {
  opacity: 0.2;
}

.agent-options .agent-item .agent-title {
  position: absolute;
  bottom: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 10px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.ability-options {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin-bottom: -14px;
}

.ability-options .ability-item {
  width: 50px;
  height: 50px;
  background: #0f1923;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
  margin-bottom: 14px;
}

.ability-options .ability-item img {
  width: 35px;
  height: 35px;
}

.map-agent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.map-agent .select-map {
  width: 190px;
  height: 70px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  position: relative;

}

.map-agent .select-map img {
  transition: transform 0.3s;
  width: 100%;
}

.map-agent .select-map:hover > img {
  transform: scale(1.2);
}


.map-agent .select-map .select-map-title {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-weight: 600;
  font-size: 20px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.map-agent .select-agent {
  width: 70px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.map-agent .select-agent .agent-title {
  position: absolute;
  bottom: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 10px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}


.map-agent .select-agent img {
  width: 100%;
  transition: transform 0.3s;
}

.map-agent .select-agent:hover > img {
  transform: scale(1.2);
}

.hover_con {
  position: fixed;
  max-width: 220px;
  padding: 5px;
  border: 1px solid #666;
  background: #ccc;
  z-index: 999;
}

.biliVideo {
  margin-top: 5px;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 60%; /* 高度应该是宽度的56% */
}

.biliVideo iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.out-parent {
  display: flex;
  width: 100%;
}

.share-item {
  flex: 1;
  height: 100%;
  margin-left: 2%;
  margin-right: 2%;
  display: flex;
  justify-items: center;
  align-items: center;
  cursor: pointer;
}
</style>