const config = {
    game: {
        cs2: {
            logoSrc: require('../assets/cs2.png'),
        },
        val: {
            logoSrc: require('../assets/val.png'),
        }
    },
    maps: {
        abyss: {
            title: 'ABYSS',
            chTitle: '幽邃地窟',
            imgSrc: require('../assets/map/abyss.webp'),
            radarImgSrc: require('../assets/map/abyssRadar.svg'),
        },
        ascent: {
            title: 'ASCENT',
            chTitle: '亚海悬城',
            imgSrc: require('../assets/map/ascent.png'),
            radarImgSrc: require('../assets/map/ascentRadar.svg'),
        },
        bind: {
            title: 'BIND',
            chTitle: '源工重镇',
            imgSrc: require('../assets/map/bind.jpeg'),
            radarImgSrc: require('../assets/map/bindRadar.svg'),
        },
        breeze: {
            title: 'BREEZE',
            chTitle: '微风岛屿',
            imgSrc: require('../assets/map/breeze.png'),
            radarImgSrc: require('../assets/map/breezeRadar.svg'),
        },
        fracture: {
            title: 'FRACTURE',
            chTitle: '裂变峡谷',
            imgSrc: require('../assets/map/fracture.jpg'),
            radarImgSrc: require('../assets/map/fractureRadar.svg'),
        },
        haven: {
            title: 'HAVEN',
            chTitle: '隐世修所',
            imgSrc: require('../assets/map/haven.png'),
            radarImgSrc: require('../assets/map/havenRadar.svg'),
        },
        icebox: {
            title: 'ICEBOX',
            chTitle: '森寒冬港',
            imgSrc: require('../assets/map/icebox.png'),
            radarImgSrc: require('../assets/map/iceboxRadar.svg'),
        },
        lotus: {
            title: 'LOTUS',
            chTitle: '莲华古城',
            imgSrc: require('../assets/map/lotus.png'),
            radarImgSrc: require('../assets/map/lotusRadar.svg'),
        },
        pearl: {
            title: 'PEARL',
            chTitle: '深海明珠',
            imgSrc: require('../assets/map/pearl.jpg'),
            radarImgSrc: require('../assets/map/pearlRadar.svg'),
        },
        split: {
            title: 'SPLIT',
            chTitle: '霓虹町',
            imgSrc: require('../assets/map/split.png'),
            radarImgSrc: require('../assets/map/splitRadar.svg'),
        },
        sunset: {
            title: 'SUNSET',
            chTitle: '日落之城',
            imgSrc: require('../assets/map/sunset.jpeg'),
            radarImgSrc: require('../assets/map/sunsetRadar.svg'),
        },
    },
    agents: {
        astra: {
            title: 'Astra',
            chTitle: '星礈',
            imgSrc: require('../assets/agent/astra.png'),
            background: "background: rgb(255, 21, 218);",
            color: '#FF15DA',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/astra/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/astra/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/astra/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/astra/4.svg')
                }
            }
        },
        breach: {
            title: 'Breach',
            chTitle: '铁臂',
            imgSrc: require('../assets/agent/breach.png'),
            background: "background: rgb(220, 177, 144);",
            color: '#dcb190',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/breach/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/breach/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/breach/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/breach/4.svg')
                }
            }
        },
        brimstone: {
            title: 'Brimstone',
            chTitle: '炼狱',
            imgSrc: require('../assets/agent/brimstone.png'),
            background: "background: rgb(127, 229, 251);",
            color: '#7fe5fb',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/brimstone/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/brimstone/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/brimstone/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/brimstone/4.svg')
                }
            }
        },
        chamber: {
            title: 'Chamber',
            chTitle: '尚勃勒',
            imgSrc: require('../assets/agent/chamber.png'),
            background: "background: rgb(255, 238, 203);",
            color: '#ffeecb',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/chamber/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/chamber/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/chamber/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/chamber/4.svg')
                }
            }
        },
        clove: {
            title: 'Clove',
            chTitle: '暮蝶',
            imgSrc: require('../assets/agent/clove.png'),
            background: "background: rgb(208, 202, 243);",
            color: '#d0caf3',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/clove/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/clove/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/clove/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/clove/4.svg')
                }
            }
        },
        cypher: {
            title: 'Cypher',
            chTitle: '零',
            imgSrc: require('../assets/agent/cypher.png'),
            background: "background: rgb(204, 206, 193);",
            color: '#cccec1',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/cypher/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/cypher/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/cypher/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/cypher/4.svg')
                }
            }
        },
        deadlock: {
            title: 'Deadlock',
            chTitle: '钢锁',
            imgSrc: require('../assets/agent/deadlock.png'),
            background: "background: rgb(222, 229, 225);",
            color: '#dee5e1',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/deadlock/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/deadlock/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/deadlock/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/deadlock/4.svg')
                }
            }
        },
        fade: {
            title: 'Fade',
            chTitle: '黑梦',
            imgSrc: require('../assets/agent/fade.png'),
            background: "background: rgb(222, 229, 225);",
            color: '#dee5e1',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/fade/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/fade/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/fade/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/fade/4.svg')
                }
            }
        },
        gekko: {
            title: 'Gekko',
            chTitle: '盖可',
            imgSrc: require('../assets/agent/gekko.png'),
            background: "background: rgb(106, 141, 24);",
            color: '#6a8d18',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/gekko/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/gekko/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/gekko/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/gekko/4.svg')
                }
            }
        },
        harbor: {
            title: 'Harbor',
            chTitle: '海神',
            imgSrc: require('../assets/agent/harbor.png'),
            background: "background: rgb(143, 202, 255);",
            color: '#8fcaff',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/harbor/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/harbor/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/harbor/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/harbor/4.svg')
                }
            }
        },
        iso: {
            title: 'Iso',
            chTitle: '壹决',
            imgSrc: require('../assets/agent/iso.png'),
            background: "background: rgb(213, 161, 255);",
            color: '#d5a1ff',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/iso/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/iso/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/iso/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/iso/4.svg')
                }
            }
        },
        jett: {
            title: 'Jett',
            chTitle: '捷风',
            imgSrc: require('../assets/agent/jett.png'),
            background: "background: rgb(118, 207, 221);",
            color: '#76cfdd',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/jett/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/jett/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/jett/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/jett/4.svg')
                }
            }
        },
        kayo: {
            title: 'Kay/o',
            chTitle: 'K/O',
            imgSrc: require('../assets/agent/kayo.png'),
            background: "background: rgb(127, 229, 251);",
            color: '#7fe5fb',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/kayo/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/kayo/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/kayo/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/kayo/4.svg')
                }
            }
        },
        killjoy: {
            title: 'Killjoy',
            chTitle: '奇乐',
            imgSrc: require('../assets/agent/killjoy.png'),
            background: "background: rgb(50, 205, 50);",
            color: '#32cd32',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/killjoy/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/killjoy/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/killjoy/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/killjoy/4.svg')
                }
            }
        },
        neon: {
            title: 'Neon',
            chTitle: '霓虹',
            imgSrc: require('../assets/agent/neon.png'),
            background: "background: rgb(213, 255, 252);",
            color: '#d5fffc',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/neon/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/neon/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/neon/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/neon/4.svg')
                }
            }
        },
        omen: {
            title: 'Omen',
            chTitle: '幽影',
            imgSrc: require('../assets/agent/omen.png'),
            background: "background: rgb(127, 229, 251);",
            color: '#7fe5fb',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/omen/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/omen/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/omen/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/omen/4.svg')
                }
            }
        },
        phoenix: {
            title: 'Phoenix',
            chTitle: '不死鸟',
            imgSrc: require('../assets/agent/phoenix.png'),
            background: "background: rgb(243, 142, 94);",
            color: '#f38e5e',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/phoenix/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/phoenix/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/phoenix/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/phoenix/4.svg')
                }
            }
        },
        raze: {
            title: 'Raze',
            chTitle: '雷兹',
            imgSrc: require('../assets/agent/raze.png'),
            background: "background: rgb(234, 189, 116);",
            color: '#eabd74',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/raze/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/raze/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/raze/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/raze/4.svg')
                },
                5: {
                    imgSrc: require('../assets/agent/raze/5.svg')
                }
            }
        },
        reyna: {
            title: 'Reyna',
            chTitle: '芮娜',
            imgSrc: require('../assets/agent/reyna.png'),
            background: "background: rgb(186, 21, 173);",
            color: '#ba15ad',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/reyna/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/reyna/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/reyna/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/reyna/4.svg')
                }
            }
        },
        sage: {
            title: 'Sage',
            chTitle: '贤者',
            imgSrc: require('../assets/agent/sage.png'),
            background: "background: rgb(102, 230, 217);",
            color: '#66e6d9',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/sage/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/sage/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/sage/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/sage/4.svg')
                }
            }
        },
        skye: {
            title: 'Skye',
            chTitle: '斯凯',
            imgSrc: require('../assets/agent/skye.png'),
            background: "background: rgb(144, 170, 117);",
            color: '#90aa75',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/skye/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/skye/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/skye/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/skye/4.svg')
                }
            }
        },
        sova: {
            title: 'Sova',
            chTitle: '猎枭',
            imgSrc: require('../assets/agent/sova.png'),
            background: "background: rgb(127, 229, 251);",
            color: '#7fe5fb',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/sova/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/sova/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/sova/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/sova/4.svg')
                },
                5: {
                    imgSrc: require('../assets/agent/sova/5.svg')
                }
            }
        },
        viper: {
            title: 'Viper',
            chTitle: '蝰蛇',
            imgSrc: require('../assets/agent/viper.png'),
            background: "background: rgb(87, 217, 72);",
            color: '#57d948',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/viper/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/viper/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/viper/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/viper/4.svg')
                }
            }
        },
        vyse: {
            title: 'Vyse',
            chTitle: '维斯',
            imgSrc: require('../assets/agent/vyse.png'),
            background: "background: rgb(159, 116, 179);",
            color: '#9f74b3',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/vyse/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/vyse/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/vyse/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/vyse/4.svg')
                }
            }
        },
        yoru: {
            title: 'Yoru',
            chTitle: '夜露',
            imgSrc: require('../assets/agent/yoru.png'),
            background: "background: rgb(127, 229, 251);",
            color: '#7fe5fb',
            ability: {
                1: {
                    imgSrc: require('../assets/agent/yoru/1.svg')
                },
                2: {
                    imgSrc: require('../assets/agent/yoru/2.svg')
                },
                3: {
                    imgSrc: require('../assets/agent/yoru/3.svg')
                },
                4: {
                    imgSrc: require('../assets/agent/yoru/4.svg')
                }
            }
        },
    },
    leaflet: {
        addLineup: {
            point: {
                radius: 10,
                startPointFillColor: '#00FF00',
                endPointFillColor: '#FFFF00',
            },
            polygon: {
                color: 'red',
                weight: 1,
            }
        },
        showLineup: {
            togetherDistance: 14,
            abilityImgSize: 40,
            agentImgSize: 50,
            agentCircle: {
                radius: 30,
                weight: 0,
                fillOpacity: 1,
            },
            endCircle: {
                radius: 30,
                fillColor: '#C8D0CF',
            },
            endCircleMouseOver: {
                radius: 40,
            },
            polygon: {
                color: 'red',
                weight: 1,
                fillOpacity: 0.3,
            },
            polylineWithEndPoint: {
                color: 'yellow',
                weight: 2,
            },
            startEndLine: {
                color: '#ffffff',
                weight: 1,
            }
        },
        mouseover: {
            polylineWithEndPoint: {
                color: 'red',
                weight: 2,
            }
        }
    },
    povUpNameList: ['清汤烧', '染歌', '22林酱', '波洛落萝', 'Lff']
}

export {config}